
import './App.css';
import Busy from './zustpe/busybee/busybee.js'
import Product from './zustpe/ourproduct/prodect.js'
import Service from './zustpe/ourservice/service.js'
import Design from './zustpe/tech/design.js'
import Contact from './zustpe/contact/contact.js'
function App() {
  return (
    <div>
   <Busy />
   <Product/>
   <Service/>
   <Design/>
   <Contact/>

   </div>
  );
}

export default App;
