import React from 'react'
import './product.css'
import ring from '../images/diamond-ring.png'
import bouquet from '../images/bouquet.png'
import cashew from '../images/cashew.png'
import chocolate from '../images/chocolate.png'
import cup from '../images/cup.png'
import decoration from '../images/decoration.png'
import eco from '../images/eco-friendly.png'
import fabric from '../images/fabric.png'
import glassware from '../images/glassware.png'
import flower from '../images/flower-pot.png'
import groceries from '../images/groceries.png'
import handmade from '../images/handmade.png'
import paper from '../images/paper-bag.png'
import pot from '../images/pot.png'
import key from '../images/room-key.png'
import sweets from '../images/sweets.png'
import vegetable from '../images/vegetable 1.png'
import water from '../images/water.png'
import wedding from '../images/wedding-card 1.png'
import oil from '../images/oil.png'
import bgc from '../images/product.svg'
import dress from '../images/dress.png'
import cutlary from '../images/cutlery.png'
function Product(){
return(
<div className='product'>
    <div className='product-head'>
        <h1>Our products</h1>
        <img src={bgc}/>
    </div>
    <div className='products'>
        <div className='our'>
            <img src={ring}/>
            <span>Artificial jewellery</span>
        </div>
        <div className='our'>
            <img src={bouquet}/>
            <span> Bouqet<br/>(chocolate &flower)</span>
        </div>
        <div className='our'>
            <img src={chocolate}/>
            <span>Chocolate / cake</span>
        </div>
        <div className='our'>
            <img src={decoration}/>
            <span>Decorations items<br/>(plates,hangings, pictures)</span>
        </div>
        {/* <div className='our'>
            <img src={eco}/>
            <span>Flowers</span>
        </div> */}
        <div className='our'>
            <img src={flower}/>
            <span>Flowers</span>
        </div>
        <div className='our'>
            <img src={glassware}/>
            <span>Glassware items</span>
        </div>
        <div className='our'>
            <img src={handmade}/>
            <span>Jute bags/Paper Bags</span>
        </div>
        <div className='our'>
            <img src={cup}/>
            <span>Icecream</span>
        </div>
        <div className='our'>
            <img src={paper}/>
            <span>Jute bags / Paper bags</span>
        </div>
        <div className='our'>
        
            <img src={key}/>
            <span>keychains</span>
        </div>
        <div className='our'>
            <img src={dress}/>
            <span>Lehanga / Choli</span>
        </div>
        <div className='our'>
            <img src={groceries}/>
            <span>Maligai products</span>
        </div>
        <div className='our'>
            <img src={cashew}/>
            <span>nuts /spices</span>
        </div>
        <div className='our'>
            <img src={oil}/>
            <span>oil items</span>
        </div>
        <div className='our'>
            <img src={pot}/>
            <span>Pottery Products</span>
        </div>
        <div className='our'>
            <img src={sweets}/>
            <span>Sweets & Snakes</span>
        </div>
        <div className='our'>
            <img src={fabric}/>
            <span>texttails </span>
        </div>
        <div className='our'>
            <img src={cutlary}/>
            <span>Utensils</span>
        </div>
        <div className='our'>
            <img src={vegetable}/>
            <span>Vegetables</span>
        </div>
        <div className='our'>
            <img src={water}/>
            <span>Water Bottles/Water Can</span>
        </div>
        <div className='our'>
            <img src={wedding}/>
            <span>Wedding card</span>
        </div>
    </div>
</div>
);
}


export default Product;