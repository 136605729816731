import React from 'react'
import './design.css'
// import title from '../images/tech/Vector.png'
import design_arrow from '../images/tech/Vector 3.png'
import develop_arrow from '../images/tech/Vector 4.png'
import design from '../images/tech/unsplash_qC2n6RQU4Vw.png'
import develop from '../images/tech/unsplash_XopauR-Nagk.png'
import bgc from '../images/tech/bgc.svg'

function Design(){
    return(
        <div className='design'>
            <div className='tech-head'>
                <h1>Technological Services</h1>
                <img src={bgc} alt='tech'/>
            </div>
            <div className='design1'>
                <div className='designleft'>
                    <img src={design} alt='d-left'/>
                </div>
                <img className='design_arrow' src={design_arrow} alt='d-right'/>
                <div className='designright'>
                    <h1>Web Design</h1>
                    <p>UI/UX Design process conducted under
full-stack execution and a defined and 
driven UI/UX stratefy for Websites, 
Web Application and Mobile Application.</p>
                </div>
            </div>
            {/* develop */}
            <div className='develop'>
                <div className='developleft'>
                    <h1>Web Development</h1>
                    <p>With expertise in Angular JS, JQuery, Bootstrap and more, Our Web development is practical, profitable and worthwhile!</p>
                </div>
                <img className='develop_arrow' src={develop_arrow} alt='develop-arrow'/>
                <div className='developright'>
                    <img src={develop} alt='develop-right'/>
                </div>
            </div>
        </div>
    )

}
export default Design;