import React from 'react'
import './contact.css'
import mail from '../images/conntact/mail.svg'
import phone from '../images/conntact/phone.svg'
import gps from '../images/conntact/map.svg'

function Contact(){
    return(
        <div id='contact'>
            <h1 className='con-h'>Contact</h1>
            <div className='contacts'>
                 <div className='contact1'>
                    <div className='con1'>
                    <img className='con-img' alt='gps' src={gps}/>
                    </div>

                    <span>No - 3/3, Kumaran Nagar Main Road, <br/>Trichy, Tamil Nadu - 620017.</span>
                 </div>
                 <div className='contact1'>
                    <div className='con2'>
                        <img className='con-img' alt='phone' src={phone}/>
                    </div>

                    <span>+91 - 8667389924</span>
                 </div>
                 <div className='contact1'>
                    <div className='con3'>
                        <img className='con-img' alt='mail' src={mail}/>
                    </div>
                    <span>enquire@busybeeventures.co.in</span>
                 </div>
            </div>
            <p>Powered by <a href='https://zustpe.com/'><span className='zustpe'>Zustpe</span></a></p>
        </div>
    )
}
export default Contact;