import React from 'react'
import './service.css'
import mackup from '../images/ourservices/makeup.png'
import catring from '../images/ourservices/catering.png'
import dj from '../images/ourservices/dj.png'
import flower from '../images/ourservices/wedding.png'
import gift from '../images/ourservices/gift-box.png'
import hotel from '../images/ourservices/review.png'
import ice from '../images/ourservices/cup.png'
import ring from '../images/ourservices/ring.png'
import kolam from '../images/ourservices/kolam.png'
import music from '../images/ourservices/concert.png'
import meganthi from '../images/ourservices/henna.png'
import hand2 from '../images/ourservices/conductor.png'
import photo from '../images/ourservices/photographer.png'
import sister from '../images/ourservices/brother.png'
import car from '../images/ourservices/car.png'
import popcorn from '../images/ourservices/popcorn.png'
import travel from '../images/ourservices/travel.png'
import pakking from '../images/ourservices/takeaway.png'
import dance from '../images/ourservices/dance.png'
import wedding from '../images/ourservices/wedding-card.png'
import bgc from '../images/ourservices/service.svg'
import aari from '../images/ourservices/aari.png'
import honeymoon from '../images/ourservices/honeymoon.png'
import siter from '../images/ourservices/sitar.png'
import drums from '../images/ourservices/drums.png'

function Service(){
    return(
        <div className='ourservice'>
            <div className='service-head'>
                <h1>Our Service</h1>
                <img src={bgc}/>
            </div>
            <div className='service'>
            <div className='services'>
                    <img src={aari}/>
                    <span>Aari Work</span>
                </div>
                <div className='services'>
                    <img src={mackup}/>
                    <span>Bridal makeup</span>
                </div>
                <div className='services'>
                    <img src={catring}/>
                    <span>Catring services</span>
                </div>
                <div className='services'>
                    <img src={dj}/>
                    <span>Dj party</span>
                </div>
                <div className='services'>
                    <img src={flower}/>
                    <span>Flowers Decoration</span>
                </div>
                <div className='services'>
                    <img src={gift}/>
                    <span>Garland/Gift packing</span>
                </div>
                <div className='services'>
                    <img src={hotel}/>
                    <span>Hall/ Hotel Room booking </span>
                </div>
                <div className='services'>
                    <img src={honeymoon}/>
                    <span>Honeymoon</span>
                </div>
                <div className='services'>
                    <img src={ring}/>
                    <span>jewellery rental</span>
                </div>
                <div className='services'>
                    <img src={siter}/>
                    <span>Karnatic music</span>
                </div>
                <div className='services'>
                    <img src={kolam}/>
                    <span>Kolam</span>
                </div>
                <div className='services'>
                    <img src={music}/>
                    <span>Light music show</span>
                </div>
                <div className='services'>
                    <img src={ice}/>
                    <span>Icecream/Beeda stall</span>
                </div>
                <div className='services'>
                    <img src={meganthi}/>
                    <span>mehandi</span>
                </div>
                <div className='services'>
                    <img src={drums}/>
                    <span>Mangala vadhyam</span>
                </div>
                <div className='services'>
                    <img src={hand2}/>
                    <span>Orchestra</span>
                </div>

                <div className='services'>
                    <img src={photo}/>
                    <span>Photography/videography</span>
                </div>
                <div className='services'>
                    <img src={sister}/>
                    <span>Reception Girls services</span>
                </div>
                <div className='services'>
                    <img src={travel}/>
                    <span>Travel arrangements</span>
                </div>
                <div className='services'>
                    <img src={pakking}/>
                    <span>Thamboolam bag packing</span>
                </div>
                <div className='services'>
                    <img src={wedding}/>
                    <span>wedding card designing & printing</span>
                </div>
                <div className='services'>
                    <img src={dance}/>
                    <span>Welcome /surprise dance</span>
                </div>

            </div>
        </div>
    );

}

export default Service;