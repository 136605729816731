import React from 'react'
import './busybee.css'
import bee from '../images/bee.png'
import vector from '../images/Vector.png'
import grow from '../images/grow.png'
import party from '../images/party.svg'
function Busy(){
    return(
        <div className='busy'>
                <img className='vector' src={vector}/>
            <div className='busyleft'>
                <h2 className='bee-h'>Busy <img className='bee'
                src={bee} />ee Ventures</h2>
                <img className='grow' src={grow}/>

                <p className='busy1'>Event Management co. & A to Z products& service provider</p>
                <p className='busy2'>We undertake A to Z services of Betrothal/Engagement, wedding, baby shower, naming ceremony, puberty/half saree function, housewarming, ganathy homam, birthday parties, nalangu, mahendi, corporate events etc.
                </p>
                <a className='busybtn' href='#contact' >Contact Us</a>
            </div>
            <div className='busyright'>
            <img src={party}/>
            </div>
        </div>
    )
}

export default Busy;